import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CredencialesPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CredencialesPage",
            ss: ss,
            ajax: {
                "url": ss.indexCredenciales(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                //{ data: 'Estudiante', name: 'ce.Estudiante', title: 'Estudiante' },
                { data: 'CodigoQr', name: 'ce.CodigoQr', title: 'CodigoQr' },
                { data: 'NombreCompleto', name: 'ce.NombreCompleto', title: 'NombreCompleto' },
                { data: 'CedulaIdentidad', name: 'ce.CedulaIdentidad', title: 'CedulaIdentidad' },
                { data: 'FechaEmision', name: 'ce.FechaEmision', title: 'FechaEmision' },
                { data: 'FechaVencimiento', name: 'ce.FechaVencimiento', title: 'FechaVencimiento' },
                { data: 'Activo', name: 'ce.Activo', title: 'Activo',searchable: false, width: '10%',
                    className: 'centradito',
                    render: function (data, type, row) {
                    if (row.Activo == false) {
                        return '<i class="fa fa-ban text-danger"> No Activo</i>';
                    } else {
                        return '<i class="fa fa-check text-success"> Activo</i>';
                    }
                    },
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            credencial: [],
            credenciales: {
                Estudiante: null,
                NombreCompleto: '',
                CedulaIdentidad: '',
                CodigoQr: '',
                FechaEmision: '',
                FechaVencimiento: '',
                Gestion: '',
                Activo: false,
            },
            isLoading: false,
            errorBag: {},
            estudiantes: [],
            selectedEstudiante: null,
            searchEstudiante: "",
            placeholderEstudiante:"",

        };
    },
    methods: {
        resetForm() {
            this.credenciales = {
                Estudiante: null,
                NombreCompleto: '',
                CedulaIdentidad: '',
                CodigoQr: '',
                FechaEmision: '',
                FechaVencimiento: '',
                Gestion: '',
                Activo: false,
            };
            this.errorBag = {};
        },
        newCredenciales() {
            this.resetForm();
            this.$refs['frm-credenciales'].show();
        },
        showCredenciales(id) {
            this.isLoading=true;
            this.ss.showCredenciales(id).then(
                (result) => {
                    let response = result.data;
                    this.credenciales = response.data;
                    this.$refs['view-credenciales'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCredenciales() {
            this.$refs['frmedit-credenciales'].show();
            
        },
        cancelCurso() {
            if (this.curso.id) {
                this.$refs['view-curso'].show();
            }
            this.$refs['frm-curso'].hide();
        },
        saveCredenciales() {
            this.ss.storeCredenciales(this.credenciales).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-credenciales'].hide();
                    this.$refs['frmedit-credenciales'].hide();
                    this.$refs['datatable-credenciales'].reload();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        this.$bvToast.toast(
                            error.response.data.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    } else {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                });
        },
        deleteCurso() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyCurso(this.curso)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-curso'].hide();
                                this.$refs['datatable-curso'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Credenciales').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCredenciales(data);
            });
        },
        printCredencial() {
            this.ss.reporteCredencial(this.credenciales)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        
        printCredencialdos() {
            this.ss.reporteCredencialdos(this.credenciales)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
       
    },
    watch: {
        searchEstudiante(q) {
            this.ss.select2Estudiante(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                   
                    this.estudiantes = res.data.items;
                
                } else {
                    this.estudiantes = [];
                }
            })
            

        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            //this.getSucursal();
        }
    }
};
