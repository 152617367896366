import { render, staticRenderFns } from "./SucursalPage.vue?vue&type=template&id=62f3f89c"
import script from "./SucursalPage.js?vue&type=script&lang=js&external"
export * from "./SucursalPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports