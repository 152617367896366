import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import SucursalPage from '@/pages/Sucursal/SucursalPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import EstudiantePage from '@/pages/Estudiante/EstudiantePage.vue'
import InscripcionPage from '@/pages/Inscripcion/InscripcionPage.vue'
import CursoPage from '@/pages/Curso/CursoPage.vue'
import CursoParaPage from '@/pages/CursoPara/CursoParaPage.vue'
import PagoPage from '@/pages/Pago/PagoPage.vue'
import AsistenciaPage from '@/pages/Asistencia/AsistenciaPage.vue'
import TipoIngresoPage from '@/pages/TipoIngreso/TipoIngresoPage.vue'
import RegistrarAsistenciaPage from '@/pages/RegistrarAsistencia/RegistrarAsistenciaPage.vue'
import RegistrarAsistenciacamPage from '@/pages/RegistrarAsistenciacam/RegistrarAsistenciacamPage.vue'
import CredencialesPage from '@/pages/Credenciales/CredencialesPage.vue'
import ReportePage from '@/pages/Reporte/ReportePage.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/Sucursal',
      name: 'Sucursal',
      component: SucursalPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/Estudiante',
      name: 'Estudiante',
      component: EstudiantePage
    },
    {
      path: '/Inscripcion',
      name: 'Inscripcion',
      component: InscripcionPage
    },
    {
      path: '/Curso',
      name: 'Curso',
      component: CursoPage
    },
    {
      path: '/CursoPara',
      name: 'CursoPara',
      component: CursoParaPage
    },
    {
      path: '/Pago',
      name: 'Pago',
      component: PagoPage
    },
    {
      path: '/Asistencia',
      name: 'Asistencia',
      component: AsistenciaPage
    },
    {
      path: '/TipoIngreso',
      name: 'TipoIngreso',
      component: TipoIngresoPage
    },
    {
      path: '/RegistrarAsistencia',
      name: 'RegistrarAsistencia',
      component: RegistrarAsistenciaPage
    },
    {
      path: '/RegistrarAsistenciacam',
      name: 'RegistrarAsistenciacam',
      component: RegistrarAsistenciacamPage
    },
    {
      path: '/Credenciales',
      name: 'Credenciales',
      component: CredencialesPage
    },
    {
      path: '/Reporte',
      name: 'Reporte',
      component: ReportePage
    }
  ]
})