import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReportePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReportePage",
            ss: ss,
            auth: {},
            isLoading: false,
            errorBag: {},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            estudiante: {},
            pago: {},
            curso: {},
            cursos: [],
            asistencia: {
                Ano: null,
                Mes: null,
            },
            estudiantes: [],
            searchEstudiante: "",
            selectedEstudiante:null,
            turnos: [
                { id: 1, nombre: 'Mañana' },
                { id: 2, nombre: 'Tarde' },
                { id: 3, nombre: 'Noche' },
            ],
            paralelos: [
                { id: 1, nombre: 'A' },
                { id: 2, nombre: 'B' },
                { id: 3, nombre: 'C' },
                { id: 4, nombre: 'D' },
                { id: 5, nombre: 'E' },
                { id: 6, nombre: 'F' },
                { id: 7, nombre: 'G' },
                { id: 8, nombre: 'H' },
                { id: 9, nombre: 'I' },
                { id: 10, nombre: 'J' },
                { id: 11, nombre: 'K' },
                { id: 12, nombre: 'L' },
                { id: 13, nombre: 'M' },
                { id: 14, nombre: 'N' },
                { id: 15, nombre: 'O' },
                { id: 16, nombre: 'P' },
                { id: 17, nombre: 'Q' },
            ],
            years: [2024, 2025], // Opciones de año
            months: [
                "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", 
                "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ] 
        };
    },
    methods: {
        ModalEstudiante() {
            this.estudiante = {};
            this.$refs['frm-estudiante'].show();
        },
        ModalCurso() {
            this.curso = {};
            this.$refs['frm-curso'].show();
        },
        ModalPago() {
            this.pago = {};
            this.$refs['frm-pago'].show();
        },
        ModalAsistencia() {
            this.asistencia = {};
            this.$refs['frm-asistencia'].show();
        },
        setResetEstudiante() {
            this.estudiante = {};
        },
        setResetPago() {
            this.pago = {};
        },
        setResetCurso() {
            this.curso = {};
        },
        setResetAsistencia() {
            this.asistencia = {};
        },
        convertirMayusculasEstudiante() {
            this.estudiante.Persona = this.estudiante.Persona.toUpperCase();
        },
        handleReport(endpoint) {
            this.ss[endpoint](this.estudiante)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir = this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        reporteestudiante() {
            this.handleReport('printestudiante');
        },
        reporteestudiantelista() {
            this.handleReport('printestudiantelista');
        },
        getCurso() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getParaleloNombre(paraleloId) {
            const paralelo = this.paralelos.find(t => t.id === paraleloId);
            return paralelo ? paralelo.nombre : 'Desconocido';
        },
        getTurnoNombre(turnoId) {
            const turno = this.turnos.find(t => t.id === turnoId);
            return turno ? turno.nombre : 'Desconocido';
        },
        handleReportPago(endpoint) {
            this.ss[endpoint](this.pago)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir = this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        reporteestudiantepago() {
            this.handleReportPago('printestudiantepago');
        },
        reporteestudiantepagodeta() {
            this.handleReportPago('printestudiantepagodeta');
        },
        reportecursopago() {
            this.handleReportPago('printcursopago');
        },
        reportecursodetallado() {
            this.handleReportPago('printcursodetallado');
        },
        reportecursodetalladoseis() {
            this.handleReportPago('printcursodetalladoseis');
        },

        handleReportCurso(endpoint) {
            this.ss[endpoint](this.curso)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir = this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        reportecursolista() {
            this.handleReportCurso('printcursolista');
        },

        handleReportAsistencia(endpoint) {
            this.asistencia.id = this.asistencia.Estudiante
            this.ss[endpoint](this.asistencia)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir = this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        reporteAsistenciaEstudiante() {
            this.handleReportAsistencia('reporteAsistenciaEstudiante');
        },
        reportecursoasistencia() {
            this.handleReportAsistencia('reportecursoasistencia');
        },
        reportecursoasistenciageneral() {
            this.handleReportAsistencia('reportecursoasistenciageneral');
        },
        reportecursoasistenciafechas() {
            this.handleReportAsistencia('reportecursoasistenciafechas');
        },
        reporteasistenciacontrol() {
            this.handleReportAsistencia('reporteasistenciacontrol');
        },
    },
    watch: {
        searchEstudiante(q) {
            this.ss.select2Estudiante(q).then((sub) => {
                console.log("",sub);
                if (sub.data.success) {
                    this.estudiantes = sub.data.items;
                } else {
                    this.estudiantes = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else{
            this.getCurso();
        }
    }
};
