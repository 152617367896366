import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CursoPage",
    data() {
        let ss = new MainService();
        var paralelos = [
            { id: 1, nombre: 'A' },
            { id: 2, nombre: 'B' },
            { id: 3, nombre: 'C' },
            { id: 4, nombre: 'D' },
            { id: 5, nombre: 'E' },
            { id: 6, nombre: 'F' },
            { id: 7, nombre: 'G' },
            { id: 8, nombre: 'H' },
            { id: 9, nombre: 'I' },
            { id: 10, nombre: 'J' },
            { id: 11, nombre: 'K' },
            { id: 12, nombre: 'L' },
            { id: 13, nombre: 'M' },
            { id: 14, nombre: 'N' },
            { id: 15, nombre: 'O' },
            { id: 16, nombre: 'P' },
            { id: 17, nombre: 'Q' },
        ];
        
        return {
            msg: "CursoParaPage",
            ss: ss,
            ajax: {
                "url": ss.indexCursoParalelo(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Sucursal', name: 's.Sucursal', title: 'Sucursal' },
                { data: 'Nombre', name: 'c.Nombre', title: 'Curso' },
                { 
                    data: 'Paralelo', 
                    name: 'c.Paralelo', 
                    title: 'Paralelo',
                    render: function(data) {
                        var paralelo = paralelos.find(p => p.id === data);
                        return paralelo ? paralelo.nombre : 'No definido';
                    }
                },
                { data: 'Gestion', name: 'c.Gestion', title: 'Gestion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            cursos: [],
            curso: {},
            print: {
                gestion: null,
                paralelo: null,
                sucursal: null
            },
            estudiantes: {},
            isLoading: false,
            errorBag: {},
            sucursales: [],
            turnos: [
                { id: 1, nombre: 'Mañana' },
                { id: 2, nombre: 'Tarde' },
                { id: 3, nombre: 'Noche' },
                ],
            paralelos: [
            { id: 1, nombre: 'A' },
            { id: 2, nombre: 'B' },
            { id: 3, nombre: 'C' },
            { id: 4, nombre: 'D' },
            { id: 5, nombre: 'E' },
            { id: 6, nombre: 'F' },
            { id: 7, nombre: 'G' },
            { id: 8, nombre: 'H' },
            { id: 9, nombre: 'I' },
            { id: 10, nombre: 'J' },
            { id: 11, nombre: 'K' },
            { id: 12, nombre: 'L' },
            { id: 13, nombre: 'M' },
            { id: 14, nombre: 'N' },
            { id: 15, nombre: 'O' },
            { id: 16, nombre: 'P' },
            { id: 17, nombre: 'Q' },
            ],
            listacurso: [], // Aquí almacenarás la lista de estudiantes
            fields: [
                { key: 'foto', label: 'Avatar' },
                 { key: 'Persona', label: 'Nombre Completo' },
                { key: 'CedulaIdentidad', label: 'Cédula Identidad' },
                { key: 'Genero', label: 'Género' },
                { key: 'Activo', label: '¿Activo?' },
            ],
        };
    },
    methods: {

        listarEstudiantesPorParalelo(params) {
            this.isLoading = true;
            console.log('Esto envía a paralelo:', params);
            const paraleloObj = this.paralelos.find(p => p.id === params.paralelo);
            const paraleloNombre = paraleloObj ? paraleloObj.nombre : 'No definido';
            this.print = params
            this.ss.listarEstudiantesPorParalelo(params).then(
                (result) => {
                    let response = result.data;
                    this.listacurso = response.data; // Asigna la lista de estudiantes al estado `listacurso`
                    this.curso = {
                        Paralelo: paraleloNombre,  // Mostrar paralelo en el modal
                        Gestion: params.gestion,   // Mostrar gestión en el modal
                        Sucursal: params.sucursal  // Mostrar sucursal en el modal
                    };
                    this.$refs['view-curso'].show(); // Muestra el modal con la lista de estudiantes
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        
        draw() {
            window.$('.btn-datatable-Curso').on('click', (evt) => {
                const paralelo = window.$(evt.target).data('paralelo'); // Obtener el paralelo desde el atributo data-paralelo
                const gestion = window.$(evt.target).data('gestion'); // Obtener la gestión desde el atributo data-gestion
                const sucursal = window.$(evt.target).data('sucursal'); // Obtener la sucursal desde el atributo data-sucursal
        
                console.log('Paralelo:', paralelo, 'Gestion:', gestion, 'Sucursal:', sucursal); // Para verificar qué se está enviando
        
                this.listarEstudiantesPorParalelo({
                    paralelo: paralelo,
                    gestion: gestion,
                    sucursal: sucursal
                });
            });
        },
        
        getSucursal() {
            this.ss.listSucursal().then(
                (result) => {
                    let response = result.data;
                    this.sucursales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getParaleloId() {
            // Busca el id del paralelo a partir del nombre
            const paraleloObj = this.paralelos.find(p => p.nombre === this.curso.Paralelo);
            return paraleloObj ? paraleloObj.id : null; // Retorna el id o null si no lo encuentra
        },
        printListaPara() {
            // Asegúrate de que `this.print` tenga los valores correctos
            this.print = {
                paralelo: this.getParaleloId(), // Asegúrate de obtener el ID del paralelo
                gestion: this.curso.Gestion,
                sucursal: this.curso.Sucursal
            };
        
            console.log('estovaaaaaaa', this.print);
        
            // Realiza la llamada al backend con los datos correctos
            this.ss.printListaPara(this.print)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir = this.rutaApp + urlFile.url;
                    window.open(dir); // Abrir el archivo generado en una nueva ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getSucursal();
        }
    }
};
