import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InscripcionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InscripcionPage",
            ss: ss,
            ajax: {
                "url": ss.indexInscripcion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'e.Persona', title: 'Estudiante' },
                { data: 'CedulaIdentidad', name: 'e.CedulaIdentidad', title: 'Cedula Identidad' },
                { data: 'Nombre', name: 'c.Nombre', title: 'Curso' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            inscripciones: [],
            inscripcion: {},
            isLoading: false,
            errorBag: {},
            cursos: [],
            estudiantes: [],
            turnos: [
                { id: 1, nombre: 'Mañana' },
                { id: 2, nombre: 'Tarde' },
                { id: 3, nombre: 'Noche' },
            ],
            paralelos: [
                { id: 1, nombre: 'A' },
                { id: 2, nombre: 'B' },
                { id: 3, nombre: 'C' },
                { id: 4, nombre: 'D' },
            ]
        };
    },
    methods: {
        getParaleloNombre(paraleloId) {
            const paralelo = this.paralelos.find(t => t.id === paraleloId);
            return paralelo ? paralelo.nombre : 'Desconocido';
        },
        getTurnoNombre(turnoId) {
            const turno = this.turnos.find(t => t.id === turnoId);
            return turno ? turno.nombre : 'Desconocido';
        },
        newInscripcion() {
            this.inscripcion = {};
            this.$refs['frm-inscripcion'].show();
        },
        showInscripcion(id) {
            this.isLoading = true;
            this.ss.showInscripcion(id).then(
                (result) => {
                    let response = result.data;
                    this.inscripcion = response.data;
                    this.$refs['view-inscripcion'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editInscripcion() {
            this.$refs['frm-inscripcion'].show();
            this.$refs['view-inscripcion'].hide();
        },
        cancelInscripcion() {
            if (this.inscripcion.id) {
                this.$refs['view-inscripcion'].show();
            }
            this.$refs['frm-inscripcion'].hide();
        },
        saveInscripcion() {
            this.ss.storeInscripcion(this.inscripcion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-inscripcion'].hide();
                    this.$refs['datatable-inscripcion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteInscripcion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyInscripcion(this.inscripcion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-inscripcion'].hide();
                                this.$refs['datatable-inscripcion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Inscripcion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInscripcion(data);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.estudiantes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCurso() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getEstudiante();
            this.getCurso();
            this.getTurnoNombre();
        }
    }
};
