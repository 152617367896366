import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";

export default {
    name: "PrincipalPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PrincipalPage",
            ss: ss,
            ajax: {
              "url": ss.getAsistenciaDiaria(),
              headers: ss.getToken(),
            },
            columns: [
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'total_marcados', name: 'total_marcados', title: 'Cantidad Marcados' },
            ],
            cantidadEstudiantes: [],
            cantidadInscripciones: [],
            cantidadCursos: [],
            cantidadEstudiantespago: [],
            inscritosPorCurso: [],
            becasPorCurso: [],
            asistenciaDiaria: [],
            credencialesPorGestion: [],
            ingresosDiarios: [],
            ingresosPorMes: [],
            estudiantesSinPago: [],
            totalIngresos: 0
        };
    },
    methods: {
        getcantidades() {
            this.ss.cantidades().then(
              (result) => {
                let response = result.data;
                this.cantidadEstudiantes = response.cantidadEstudiantes;
                this.cantidadInscripciones = response.cantidadInscripciones;
                this.cantidadCursos = response.cantidadCursos;
                this.cantidadEstudiantespago = response.cantidadEstudiantespago;
                this.inscritosPorCurso = response.inscritosPorCurso;
                this.becasPorCurso = response.becasPorCurso;
                this.asistenciaDiaria = response.asistenciaDiaria;
                this.credencialesPorGestion = response.credencialesPorGestion;
                this.ingresosDiarios = response.ingresosDiarios;
                this.ingresosPorMes = response.ingresosPorMes;
                this.estudiantesSinPago = response.estudiantesSinPago;
                this.totalIngresos = response.totalIngresos;
              }
            ).catch(error => {
              console.log(error);
            });
          },
          draw() {
            
          },
          
    },
    computed: {
      totalIngresosDiarios() {
          return this.ingresosDiarios.reduce((sum, ingreso) => {
              return sum + parseFloat(ingreso.total_ingresos);
          }, 0).toFixed(2);
      },
      totalIngresosMensuales() {
        return this.ingresosPorMes.reduce((sum, ingreso) => {
            return sum + parseFloat(ingreso.total_ingresos);
        }, 0).toFixed(2);
    }
  },
    components: {
      dataTable
  },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getcantidades();
        }
    }
};
