import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "EstudiantePage",
    data() {
        let ss = new MainService();
        return {
            msg: "EstudiantePage",
            ss: ss,
            ajax: {
                "url": ss.indexEstudiante(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'e.Persona', title: 'Estudiante' },
                { data: 'CedulaIdentidad', name: 'e.CedulaIdentidad', title: 'Cedula Identidad' },
                { data: 'CodigoQr', name: 'e.CodigoQr', title: 'Codigo Qr' },
                { data: 'Celular', name: 'e.Celular', title: 'Celular' },
                { data: 'CelularPadre', name: 'e.CelularPadre', title: 'Celular Padre' },
                {
                    data: 'Activo', name: 'e.Activo', title: 'Activo', searchable: false, width: '10%',
                    className: 'centradito',
                    render: function (data, type, row) {
                        if (row.Activo == false) {
                            return '<i class="fa fa-ban text-danger"> No Activo</i>';
                        } else {
                            return '<i class="fa fa-check text-success"> Activo</i>';
                        }
                    },
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            isLoadingFile: false,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            sucursales: [],
            estudiantes: [],
            isLoading: false,
            errorBag: {},
            selectedSexo: '',
            sexos: [
                { id: 1, nombre: 'Masculino' },
                { id: 2, nombre: 'Femenino' },
            ],
            inscripcion: {},
            cursos: [],
            inscripcionestudiante: [],
            turnos: [
                { id: 1, nombre: 'Mañana' },
                { id: 2, nombre: 'Tarde' },
                { id: 3, nombre: 'Noche' },
            ],
            paralelos: [
                { id: 1, nombre: 'A' },
                { id: 2, nombre: 'B' },
                { id: 3, nombre: 'C' },
                { id: 4, nombre: 'D' },
                { id: 5, nombre: 'E' },
                { id: 6, nombre: 'F' },
                { id: 7, nombre: 'G' },
                { id: 8, nombre: 'H' },
                { id: 9, nombre: 'I' },
                { id: 10, nombre: 'J' },
                { id: 11, nombre: 'K' },
                { id: 12, nombre: 'L' },
                { id: 13, nombre: 'M' },
                { id: 14, nombre: 'N' },
                { id: 15, nombre: 'O' },
                { id: 16, nombre: 'P' },
                { id: 17, nombre: 'Q' },
            ],
            timeoutId: null,
            pago: {},
            detallepago: {},
            pagonuevo: {},
            recibo: {},
            capara: {},
            pagoestudiante: [],
            pagoestudianteplan: [],
            listrecibosestudiante: [],
            asistenciaestudiante: [],
            tipoingresos: [],
            estudiante: {
                id: null
            },
            print: {
                id: null
            },
            mostrarBeca: false,
            editar: 0,
            pagoParticular: {
                Pago: null,
                Monto: '',
                Nombre: ''
            },
            detalle_pago_id: null,
            selectedParalelo: null
        };
    },
    methods: {
        checkBeca() {
            this.mostrarBeca = this.pago.TipoIngreso === 1;
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.estudiante.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getParaleloNombre(paraleloId) {
            const paralelo = this.paralelos.find(t => t.id === paraleloId);
            return paralelo ? paralelo.nombre : 'Desconocido';
        },
        getTurnoNombre(turnoId) {
            const turno = this.turnos.find(t => t.id === turnoId);
            return turno ? turno.nombre : 'Desconocido';
        },
        newEstudiante() {
            this.estudiante = {};
            this.editar = 0;
            this.$refs['frm-estudiante'].show();
        },
        confirmarAnulacion(detallePagoId) {
            this.$swal({
                title: "¿Estás seguro que deseas anular?",
                text: "¡Esta acción es irreversible!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.anularPago(detallePagoId);
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                    // Opcional: Manejar el caso de cancelación si lo necesitas
                    console.log("Anulación cancelada");
                }
            });
        },
        
        anularPago(detallePagoId) {
            this.detalle_pago_id = detallePagoId;
            this.ss.AnularPago(this.detalle_pago_id)
                .then(response => {
                    if (response.data.success) {
                        alert('Pago anulado correctamente.');
                    } else {
                        alert(response.data.msg);
                    }
                })
                .catch(error => {
                    console.error(error);
                    alert('Hubo un error al anular el pago.');
                });
        },
        cambiarEstadoRecibo(reciboId) {
            this.$swal({
                title: "¿Estás seguro que deseas cambiar el estado?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willChange) => {
                    if (willChange) {
                        this.ss.cambiarEstadoRecibo(reciboId)
                            .then(response => {
                                if (response.data.success) {
                                    this.$bvToast.toast(
                                        response.data.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    );
                                    this.fetchRecibos(); // Actualizar la lista de recibos
                                } else {
                                    alert(response.data.msg);
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                alert('Hubo un error al cambiar el estado del recibo.');
                            });
                    }
                });
        },
        showEstudiante(id) {
            this.isLoading = true;
            this.clearEstudianteData();
            this.estudiante.id = id;
            this.ss.showEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.estudiante = response.data;
                    this.$refs['view-estudiante'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        clearEstudianteData() {
            this.estudiante = {
                id: null,
                Persona: '',
                CedulaIdentidad: '',
                CodigoQr: '',
                Celular: '',
                CelularPadre: '',
                // Añadir cualquier otro campo que necesite ser limpiado
            };
            this.inscripcionestudiante = [];
            this.pagoestudiante = [];
            this.pagoestudianteplan = [];
            this.asistenciaestudiante = [];
        },
        editEstudiante() {
            this.editar = 1;
            this.$refs['frm-estudiante'].show();
            this.$refs['view-estudiante'].hide();
        },
        cancelEstudiante() {
            if (this.estudiante.id) {
                this.$refs['view-estudiante'].show();
            }
            this.$refs['frm-estudiante'].hide();
        },
        saveEstudiante() {
            this.ss.storeEstudiante(this.estudiante).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-estudiante'].hide();
                    this.$refs['datatable-estudiante'].reload();
                })
                .catch((error) => {
                    let detailMessage = 'Problema al Guardar el Registro, favor verificar los Datos';
                    if (error.response && error.response.data && error.response.data.exception.includes('QueryException')) {
                        let errorMessage = error.response.data.message;
                        console.error('Error en la base de datos:', errorMessage);
                        let match = errorMessage.match(/DETAIL: (.*)\./);
                        if (match && match[1]) {
                            detailMessage = match[1];
                        }
                    } else {
                        console.error('Error:', error);
                    }
                    this.$bvToast.toast(
                        detailMessage,
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteEstudiante() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyEstudiante(this.estudiante)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-estudiante'].hide();
                                this.$refs['datatable-estudiante'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Estudiante').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.clearEstudianteData();
                this.showEstudiante(data);
                this.getInscripcionEstudiante(data);
                this.getPagoEstudiante(data);
                this.getRecibosEst(data);
                this.getAsistenciaEstudiante(data);
            });
        },
        getSucursal() {
            this.ss.listSucursal().then(
                (result) => {
                    let response = result.data;
                    this.sucursales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newInscripcion() {
            this.inscripcion = {};
            this.$refs['frm-inscripcion'].show();
        },
        getCurso() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        saveInscripcion() {
            this.inscripcion.Estudiante = this.estudiante.id;
            this.ss.storeInscripcion(this.inscripcion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-inscripcion'].hide();
                    this.savePago();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        savePago() {
            this.pagonuevo.Estudiante = this.estudiante.id;
            this.pagonuevo.Adelanto = this.inscripcion.Adelanto;
            this.pagonuevo.Curso = this.inscripcion.Curso;
            this.pagonuevo.TipoIngreso = this.pago.TipoIngreso;
            this.pagonuevo.PorcentajeBeca = this.inscripcion.PorcentajeBeca;
            console.log('tipo ingreso esto va', this.pago.TipoIngreso);
            console.log('esto se fue de pago', this.pagonuevo);
            this.ss.storePago(this.pagonuevo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editarPago() {
            console.log('esto se fue de pago', this.pago);
            this.ss.storePago(this.pago).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-pago'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editarDetallePago() {
            console.log('esto se fue de detallepago', this.detallepago);
            this.ss.storeDetallePago(this.detallepago).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-detallepago'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        showPago(id) {
            this.isLoading = true;
            this.ss.showPago(id).then(
                (result) => {
                    let response = result.data;
                    this.pago = response.data;
                    this.$refs['frm-pago'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        showDetallePago(id) {
            this.isLoading = true;
            this.ss.showDetallePago(id).then(
                (result) => {
                    let response = result.data;
                    this.detallepago = response.data;
                    this.$refs['frm-detallepago'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        // editPago(id) {
        //     this.pago.id = id;
        //     this.showPago(id);
        //     this.$refs['frm-pago'].show();
        // },
        limpiarDetallePago() {
            this.detallepago = {
                id: null,
                Pago: '',
                NumeroCuota: '',
                MontoCuota: '',
                Cuota: '',
                Saldo: '',
                FechadePago: '',
                FechaPago: '',
                Pagado: false,
                // Otros campos que necesites limpiar
            };
        },
        editDetallePago(id) {
            this.limpiarDetallePago();
            this.detallepago.id = id;
            this.showDetallePago(id);
            this.$refs['frm-detallepago'].show();
        },
        getTipoIngreso() {
            this.ss.listTipoIngreso().then(
                (result) => {
                    let response = result.data;
                    this.tipoingresos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getInscripcionEstudiante(id) {
            //this.estudiante.id = id;
            this.ss.listInscripcionEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.inscripcionestudiante = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPagoEstudiante(id) {
            //this.estudiante.id = id;
            this.ss.listPagoEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.pagoestudiante = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPagoEstudiantePlan(id) {
            //this.estudiante.id = id;
            this.ss.listPagoEstudiantePlan(id).then(
                (result) => {
                    let response = result.data;
                    this.pagoestudianteplan = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRecibosEst(id) {
            //this.estudiante.id = id;
            this.ss.listRecibosEst(id).then(
                (result) => {
                    let response = result.data;
                    this.listrecibosestudiante = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAsistenciaEstudiante(id) {
            //this.estudiante.id = id;
            this.ss.listAsistenciaEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.asistenciaestudiante = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        printAsistenciaEstudiante() {
            this.ss.reporteAsistenciaEstudiante(this.estudiante)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        printPlanPago() {
            this.ss.reportePlanPago(this.estudiante)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        printEstudianteRecibo() {
            this.ss.reporteEstudianteRecibo(this.detallepago)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirreciboestudiante(id) {
            console.log("este iddddddddddddddddd se va", id);
            this.print.id = id;
            this.ss.reporteReciboEstudiante(this.print)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirreciboestudianteqr(id) {
            console.log("este iddddddddddddddddd se va", id);
            this.print.id = id;
            this.ss.reporteReciboEstudianteqr(this.print)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        newPagoParticular(pagoId) {
            this.pagoParticular = {
                Pago: pagoId,
                Monto: '',
                Nombre: ''
            };
            this.$refs['frm-pago-particular'].show();
        },
        savePagoParticular() {
            if (!this.pagoParticular.Pago) {
                this.$bvToast.toast(
                    'El campo Pago es obligatorio.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                );
                return;
            }

            this.ss.storePagoParticular(this.pagoParticular).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-pago-particular'].hide();
                    this.$refs['datatable-estudiante'].reload();
                })
                .catch((error) => {
                    let detailMessage = 'Problema al Guardar el Registro, favor verificar los Datos';
                    if (error.response && error.response.data && error.response.data.exception.includes('QueryException')) {
                        let errorMessage = error.response.data.message;
                        console.error('Error en la base de datos:', errorMessage);
                        let match = errorMessage.match(/DETAIL: (.*)\./);
                        if (match && match[1]) {
                            detailMessage = match[1];
                        }
                    } else {
                        console.error('Error:', error);
                    }
                    this.$bvToast.toast(
                        detailMessage,
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteInscripcion(inscripcionId) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyInscripcion({ id: inscripcionId })
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                );
                                // Actualiza la lista de inscripciones después de la eliminación
                                this.inscripcionestudiante = this.inscripcionestudiante.filter(ie => ie.id !== inscripcionId);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                });
        },
        scheduleNextUpdate() {
            this.timeoutId = setTimeout(() => {
                if (this.$el) {
                    this.getInscripcionEstudiante(this.estudiante.id);
                    this.getPagoEstudiante(this.estudiante.id);
                    this.getPagoEstudiantePlan(this.estudiante.id);
                    this.getRecibosEst(this.estudiante.id);
                    this.getAsistenciaEstudiante(this.estudiante.id);

                    // Programa la próxima actualización
                    this.scheduleNextUpdate();
                } else {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            }, 7000); // Actualiza cada 5 segundos
        },
        eliminarRecibo(reciboId) {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¡Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    this.ss.destroyRecibo({ id: reciboId })
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            );
                            // Recargar la lista de recibos después de la eliminación
                            this.listrecibosestudiante = this.listrecibosestudiante.filter(re => re.id !== reciboId);
                        })
                        .catch(error => {
                            this.$bvToast.toast(
                                'Error al eliminar el recibo',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            );
                            console.log(error);
                        });
                }
            });
        },
        cambiarparalelo() {
            this.$refs['frm-cambiarparalelo'].show();
        },
        savecambiarParalelo() {
            if (this.inscripcionestudiante && this.inscripcionestudiante.length > 0) {
                this.capara.id = this.inscripcionestudiante[0].id; // Ahora usa el id de la inscripción
            } else {
                console.error("No se encontró una inscripción válida");
                return;
            }
            console.log('estodddddd', this.capara);
            this.ss.storecambiarParalelo(this.capara).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-cambiarparalelo'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        }
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getSucursal();
            this.getCurso();
            this.getInscripcionEstudiante();
            this.getPagoEstudiante();
            this.getRecibosEst();
            this.getAsistenciaEstudiante();
            this.getTipoIngreso();

            // Verifica si this.estudiante.id tiene un valor válido antes de activar el setInterval
            if (this.estudiante.id) {
                this.scheduleNextUpdate();
            }
        }
    },
    components: {
        dataTable,
        Loader
    },
    watch: {
        'estudiante.id': function (newValue) {
            // Detiene el timeout actual (si existe) antes de cambiar el estudiante
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }

            // Verifica si el nuevo valor de this.estudiante.id es válido
            if (newValue && !isNaN(newValue)) {
                // Activa un nuevo setTimeout solo si el estudiante tiene un ID válido
                this.scheduleNextUpdate();
            }
        }
    },
    beforeDestroy() {
        // Detiene el timeout antes de destruir el componente
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }
};

