import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PagoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PagoPage",
            ss: ss,
            ajax: {
                "url": ss.indexPago(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'e.Persona', title: 'Estudiante' },
                { data: 'CedulaIdentidad', name: 'e.CedulaIdentidad', title: 'Cedula Identidad' },
                { data: 'DuracionMeses', name: 'pa.DuracionMeses', title: 'Cant. Meses' },
                { data: 'tipoingre', name: 'ti.Nombre', title: 'Tipo Ingreso' },
                { data: 'MontoBeca', name: 'pa.MontoBeca', title: 'Monto Beca' },
                { data: 'Nombre', name: 'c.Nombre', title: 'Curso' },
                { data: 'Adelanto', name: 'pa.Adelanto', title: 'Adelanto' },
                { data: 'TotalCurso', name: 'pa.TotalCurso', title: 'Total Curso' },
                { data: 'FaltaPagar', name: 'pa.FaltaPagar', title: 'FaltaPago' },
                { data: 'MontoBeca', name: 'pa.MontoBeca', title: 'Monto Beca' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            pagoes: [],
            pago: {
                detalles_pago: []
            },
            isLoading: false,
            errorBag: {},
            estudiantes: [],
            tipoingresos: [],
            cursos: [],
            detallepago: {
                NumeroCuota: '',
                MontoCuota: '',
                Saldo: '',
                Pagado: false,
                FechaPago: '',
                Nombre: ''
            },
            detalleid: {
                id: null
            }
            
        };
    },
    methods: {
        newPago() {
            this.pago = {};
            this.$refs['frm-pago'].show();
        },
        showPago(id) {
            this.isLoading=true;
            this.ss.showPago(id).then(
                (result) => {
                    let response = result.data;
                    this.pago = response.data;
                    this.$refs['view-pago'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPago() {
            this.$refs['frm-pago'].show();
            this.$refs['view-pago'].hide();
        },
        cancelPago() {
            if (this.pago.id) {
                this.$refs['view-pago'].show();
            }
            this.$refs['frm-pago'].hide();
        },
        savePago() {
            this.ss.storePago(this.pago).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-pago'].hide();
                    this.$refs['datatable-pago'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePago() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPago(this.pago)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-pago'].hide();
                                this.$refs['datatable-pago'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Pago').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPago(data);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.estudiantes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoIngreso() {
            this.ss.listTipoIngreso().then(
                (result) => {
                    let response = result.data;
                    this.tipoingresos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCurso() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

       editarDetallePago(detalle) {
        this.isLoading = true;
            console.log('este id se va de detalle', detalle.id);

            // Realizar la solicitud al backend usando el id del detalle
            this.ss.showDetallePago(detalle.id).then(
                (result) => {
                    let response = result.data;

                    // Asignar los datos recibidos a this.detallepago
                    this.detallepago = response.data;

                    // Mostrar el modal para editar el detalle del pago
                    this.$refs['frm-detallepago'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        saveDetallePago() {
            this.ss.storeDetallePagoindividual(this.detallepago).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    );
                    console.log(response);
                    this.$refs['frm-detallepago'].hide(); // Cierra el modal de edición
                    this.cargarDetallesPago(this.pago.id);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cargarDetallesPago(pagoId) {
            // Aquí se carga solo los detalles del pago
            this.ss.showPago(pagoId).then(result => {
                let response = result.data;
                response.data.detalles_pago.sort((a, b) => a.id - b.id);
                this.pago.detalles_pago = response.data.detalles_pago;
            }).catch(error => {
                console.error('Error al cargar los detalles del pago:', error);
            });
        },
                confirmarEliminacion(detalle) {
                    this.$bvModal
                        .msgBoxConfirm('¿Estás seguro que deseas eliminar este detalle de pago?', {
                            title: 'Confirmar Eliminación',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'Sí',
                            cancelTitle: 'No',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(value => {
                            if (value) {
                                this.eliminarDetallePago(detalle.id);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                },
                eliminarDetallePago(id) {
                    console.log('este id envia para eliminar', id);
                    this.detalleid.id = id
                    this.ss.deleteDetallePago( this.detalleid).then(
                        (result) => {
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Eliminado',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            );
                            
                            // Recargar los detalles del pago
                            this.cargarDetallesPago(this.pago.id);
                        })
                        .catch((error) => {
                            this.$bvToast.toast(
                                'Error al eliminar el detalle de pago',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            );
                            console.error('Error al eliminar el detalle de pago:', error);
                        });
                },
        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getEstudiante();
            this.getTipoIngreso();
            this.getCurso();
            this.cargarDetallesPago(this.pago.id);
        }
    }
};
