import MainService from "@/services/MainService.js";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "AsistenciaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "AsistenciaPage",
            ss: ss,
            isLoading: false,
            errorBag: {},
            estudiante: {},
            qrCode: "",
            estudianteactual: {},
            debounceTimeout: null, 
            debounceDelay: 300 
        };
    },
    methods: {
        registrarasistenciaautomatica() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                if (this.qrCode) {
                    this.isLoading = true;
                    console.log('Código QR escaneado:', this.qrCode);
                    this.ss.showqrEstudiante(this.qrCode).then(
                        (result) => {
                            let response = result.data;
                            if (response.success) {
                                this.estudiante = response.data;
                                this.estudianteactual.Estudiante = this.estudiante.id;
                                console.log('Estudiante encontrado:', this.estudiante);
                                this.ss.storeqrAsistencia(this.estudianteactual).then(
                                    (result) => {
                                        let response = result.data;
                                        this.estudianteactual = response.data;
                                        this.isLoading = false;
                                        this.qrCode = ""; // Reiniciar el código QR después de procesar la asistencia
                                    }
                                ).catch(error => {
                                    console.log('Error al guardar la asistencia:', error);
                                    this.isLoading = false;
                                });
                            } else {
                                console.log('Error en la respuesta del servidor:', response.msg);
                                this.isLoading = false;
                            }
                        }
                    ).catch(error => {
                        console.log('Error al buscar al estudiante:', error);
                        this.isLoading = false;
                    });
                }
            }, this.debounceDelay); // Solo ejecuta si no hay nuevas lecturas dentro del delay
        }
    },
    components: {
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
