import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CursoPage",
    data() {
        let ss = new MainService();
        var paralelos = [
            { id: 1, nombre: 'A' },
            { id: 2, nombre: 'B' },
            { id: 3, nombre: 'C' },
            { id: 4, nombre: 'D' },
            { id: 5, nombre: 'E' },
            { id: 6, nombre: 'F' },
            { id: 7, nombre: 'G' },
            { id: 8, nombre: 'H' },
            { id: 9, nombre: 'I' },
            { id: 10, nombre: 'J' },
            { id: 11, nombre: 'K' },
            { id: 12, nombre: 'L' },
            { id: 13, nombre: 'M' },
            { id: 14, nombre: 'N' },
            { id: 15, nombre: 'O' },
            { id: 16, nombre: 'P' },
            { id: 17, nombre: 'Q' },
        ];
        var turnos = [
            { id: 1, nombre: 'Mañana' },
            { id: 2, nombre: 'Tarde' },
            { id: 3, nombre: 'Noche' },
            ];
        return {
            msg: "CursoPage",
            ss: ss,
            ajax: {
                "url": ss.indexCurso(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Sucursal', name: 's.Sucursal', title: 'Sucursal' },
                { data: 'Nombre', name: 'c.Nombre', title: 'Curso' },
                { 
                    data: 'Paralelo', 
                    name: 'c.Paralelo', 
                    title: 'Paralelo',
                    render: function(data,) {
                        var paralelo = paralelos.find(p => p.id === data);
                        return paralelo ? paralelo.nombre : 'No definido';
                    }
                },
                { 
                    data: 'Turno', 
                    name: 'c.Turno', 
                    title: 'Turno',
                    render: function(data,) {
                        // Encuentra el nombre del paralelo usando el ID
                        var turno = turnos.find(p => p.id === data);
                        return turno ? turno.nombre : 'No definido';
                    }
                },
                { data: 'DuracionMeses', name: 'c.DuracionMeses', title: 'Duración Meses',width: '5%', },
                { data: 'Precio', name: 'c.Precio', title: 'Precio' },
                { data: 'HoraInicio', name: 'c.HoraInicio', title: 'Hora Inicio' },
                { data: 'HoraFin', name: 'c.HoraFin', title: 'Hora Final' },
                { data: 'Activo', name: 'c.Activo', title: 'Activo',searchable: false, width: '10%',
                    className: 'centradito',
                    render: function (data, type, row) {
                    if (row.Activo == false) {
                        return '<i class="fa fa-ban text-danger"> No Activo</i>';
                    } else {
                        return '<i class="fa fa-check text-success"> Activo</i>';
                    }
                    },
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            cursos: [],
            curso: {},
            print: {},
            estudiantes: {},
            isLoading: false,
            errorBag: {},
            sucursales: [],
            turnos: [
                { id: 1, nombre: 'Mañana' },
                { id: 2, nombre: 'Tarde' },
                { id: 3, nombre: 'Noche' },
                ],
            paralelos: [
            { id: 1, nombre: 'A' },
            { id: 2, nombre: 'B' },
            { id: 3, nombre: 'C' },
            { id: 4, nombre: 'D' },
            { id: 5, nombre: 'E' },
            { id: 6, nombre: 'F' },
            { id: 7, nombre: 'G' },
            { id: 8, nombre: 'H' },
            { id: 9, nombre: 'I' },
            { id: 10, nombre: 'J' },
            { id: 11, nombre: 'K' },
            { id: 12, nombre: 'L' },
            { id: 13, nombre: 'M' },
            { id: 14, nombre: 'N' },
            { id: 15, nombre: 'O' },
            { id: 16, nombre: 'P' },
            { id: 17, nombre: 'Q' },
            ],
            listacurso: [], // Aquí almacenarás la lista de estudiantes
            fields: [
                { key: 'foto', label: 'Avatar' },
                 { key: 'Persona', label: 'Nombre Completo' },
                // { key: 'Nombres', label: 'Nombre' },
                // { key: 'ApellidoPaterno', label: 'Apellido Paterno' },
                // { key: 'ApellidoMaterno', label: 'Apellido Materno' },
                { key: 'CedulaIdentidad', label: 'Cédula Identidad' },
                { key: 'Genero', label: 'Género' },
                { key: 'Activo', label: '¿Activo?' },
                //{ key: 'actions', label: 'Acciones', sortable: false }
            ]
        };
    },
    methods: {
        
        newCurso() {
            this.curso = {};
            this.$refs['frm-curso'].show();
        },
        showCurso(id) {
            this.isLoading=true;
            this.ss.showCurso(id).then(
                (result) => {
                    let response = result.data;
                    this.curso = response.data;
                    this.$refs['view-curso'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCurso() {
            this.$refs['frm-curso'].show();
            this.$refs['view-curso'].hide();
        },
        cancelCurso() {
            if (this.curso.id) {
                this.$refs['view-curso'].show();
            }
            this.$refs['frm-curso'].hide();
        },
        saveCurso() {
            this.ss.storeCurso(this.curso).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-curso'].hide();
                    this.$refs['datatable-curso'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCurso() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyCurso(this.curso)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-curso'].hide();
                                this.$refs['datatable-curso'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Curso').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCurso(data);
            });
        },
        getSucursal() {
            this.ss.listSucursal().then(
                (result) => {
                    let response = result.data;
                    this.sucursales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        estudiantesInscritos(id) {
            this.isLoading=true;
            this.ss.estudiantesInscritos(id).then(
                (result) => {
                    let response = result.data;
                    this.listacurso = response.data;
                    console.log(this.listacurso);
                    this.$refs['frm-listacurso'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        cancelListaCurso() {
            this.$refs['frm-listacurso'].hide();
        },
        imprimirinscritos(id) {
            console.log('viaj este id para imprimir o no',id)
            console.log("este iddddddddddddddddd se va", id);
            this.print.id = id
            this.ss.reporteInscritosCurso(this.print)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirinscritosOrden(id) {
            console.log('viaj este id para imprimir o no',id)
            console.log("este iddddddddddddddddd se va", id);
            this.print.id = id
            this.ss.reporteInscritosCursoOrden(this.print)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        
    },
    computed: {
        nombreTurno() {
            return this.turnos.find(turno => turno.id === this.curso.Turno)?.nombre || 'No definido';
        },
        nombreParalelo() {
            return this.paralelos.find(paralelo => paralelo.id === this.curso.Paralelo)?.nombre || 'No definido';
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getSucursal();
        }
    }
};
