import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "AsistenciaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "AsistenciaPage",
            ss: ss,
            ajax: {
                "url": ss.indexAsistencia(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'e.Persona', title: 'Estudiante' },
                { data: 'CedulaIdentidad', name: 'e.CedulaIdentidad', title: 'Cedula Identidad' },
                { data: 'Fecha', name: 'a.Fecha', title: 'Fecha' },
                { data: 'HoraLlegada', name: 'a.HoraLlegada', title: 'Hora Llegada' },
                {
                    data: 'Asistio', name: 'a.Asistio', title: 'Asistio', render: function (data, type, row) {
                        if (row.Asistio == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> No Asistío</i></span>'; }
                        else { return '<i class="fa fa-check text-success font-italic font-weight-bold"> Asistío </i></span>'; }
                    }
                },
                {
                    data: 'ConPermiso',
                    name: 'a.ConPermiso',
                    title: 'Permiso',
                    render: function (data, type, row) {
                        if (row.ConPermiso == false) {
                            return '<span class="text-success font-italic font-weight-bold">No</span>';
                        } else {
                            return '<span class="text-warning font-italic font-weight-bold">Si</span>';
                        }
                    }
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            asistenciaes: [],
            asistencia: {},
            isLoading: false,
            errorBag: {},
            cursos: [],
            estudiantes: [],
            selectedEstudiante: null,
            searchEstudiante: "",
            placeholderEstudiante:"",
        };
    },
    methods: {
        newAsistencia() {
            this.asistencia = {};
            this.$refs['frm-asistencia'].show();
        },
        showAsistencia(id) {
            this.isLoading = true;
            this.ss.showAsistencia(id).then(
                (result) => {
                    let response = result.data;
                    this.asistencia = response.data;
                    this.$refs['view-asistencia'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editAsistencia() {
            this.$refs['frm-asistencia'].show();
            this.$refs['view-asistencia'].hide();
        },
        cancelAsistencia() {
            if (this.asistencia.id) {
                this.$refs['view-asistencia'].show();
            }
            this.$refs['frm-asistencia'].hide();
        },
        saveAsistencia() {
            this.ss.storeAsistencia(this.asistencia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-asistencia'].hide();
                    this.$refs['datatable-asistencia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteAsistencia() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyAsistencia(this.asistencia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-asistencia'].hide();
                                this.$refs['datatable-asistencia'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Asistencia').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showAsistencia(data);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.estudiantes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCurso() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
    },
    watch: {
        searchEstudiante(q) {
            this.ss.select2Estudiante(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                   
                    this.estudiantes = res.data.items;
                
                } else {
                    this.estudiantes = [];
                }
            })
            

        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getEstudiante();
            this.getCurso();
        }
    }
};
