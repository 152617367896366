var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showSucursal(id) {
        return axios.get('api/Sucursal/show?id=' + id);
    }

    indexSucursal() {
        return axios.defaults.baseURL + 'api/Sucursal/index';
    }

    listSucursal() {
        return axios.get('api/Sucursal/list');
    }

    storeSucursal(item) {
        return axios.post('api/Sucursal/store', item);
    }

    destroySucursal(item) {
        return axios.post('api/Sucursal/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

    //**************Estudiante************/
    showEstudiante(id) {
        return axios.get('api/Estudiante/show?id=' + id);
    }

    showqrEstudiante(item) {
        return axios.get('api/Estudiante/showqr', {
            params: {
                qrCode: item // Supongo que el parámetro se llama qrCode
            }
        });
    }

    indexEstudiante() {
        return axios.defaults.baseURL + 'api/Estudiante/index';
    }

    listEstudiante() {
        return axios.get('api/Estudiante/list');
    }

    storeEstudiante(item) {
        return axios.post('api/Estudiante/store', item);
    }

    destroyEstudiante(item) {
        return axios.post('api/Estudiante/destroy', item);
    }

    select2Estudiante(item) {
        return axios.get('api/Estudiante/select2?q=' + item);
   }


    //**************Inscripcion************/
    showInscripcion(id) {
        return axios.get('api/Inscripcion/show?id=' + id);
    }

    indexInscripcion() {
        return axios.defaults.baseURL + 'api/Inscripcion/index';
    }

    listInscripcion() {
        return axios.get('api/Inscripcion/list');
    }

    listInscripcionEstudiante(id) {
        return axios.get('api/Inscripcion/listInscripcionEstudiante?id=' + id);
    }

    storeInscripcion(item) {
        return axios.post('api/Inscripcion/store', item);
    }

    storecambiarParalelo(item) {
        return axios.post('api/Inscripcion/storecambiarParalelo', item);
    }

    destroyInscripcion(item) {
        return axios.post('api/Inscripcion/destroy', item);
    }

    printListaPara(item) {
        return axios.post('api/Inscripcion/printListaPara', item);
    }

    //**************Curso************/
    showCurso(id) {
        return axios.get('api/Curso/show?id=' + id);
    }

    listarEstudiantesPorParalelo(params) {
        return axios.get('api/Curso/listarEstudiantesPorParalelo', { params });
    }   

    indexCurso() {
        return axios.defaults.baseURL + 'api/Curso/index';
    }

    indexCursoParalelo() {
        return axios.defaults.baseURL + 'api/Curso/listarCursosPorParalelo';
    }

    listCurso() {
        return axios.get('api/Curso/list');
    }

    storeCurso(item) {
        return axios.post('api/Curso/store', item);
    }

    destroyCurso(item) {
        return axios.post('api/Curso/destroy', item);
    }

    estudiantesInscritos(id) {
        return axios.get('api/Curso/estudiantesInscritos?id=' + id);
    }

    reporteInscritosCurso(item) {
        return axios.post('api/Curso/reporteInscritosCurso', item);
    }

    reporteInscritosCursoOrden(item) {
        return axios.post('api/Curso/reporteInscritosCursoOrden', item);
    }

    //**************Pago************/
    showPago(id) {
        return axios.get('api/Pago/show?id=' + id);
    }

    AnularPago(id) {
        return axios.get('api/Pago/AnularPago?detalle_pago_id=' + id);
    }

    showDetallePago(id) {
        return axios.get('api/Pago/showdetalle?id=' + id);
    }

    indexPago() {
        return axios.defaults.baseURL + 'api/Pago/index';
    }

    listPago() {
        return axios.get('api/Pago/list');
    }

    listPagoEstudiante(id) {
        return axios.get('api/Pago/listPagoEstudiante?id=' + id);
    }

    listPagoEstudiantePlan(id) {
        return axios.get('api/Pago/listPagoEstudiantePlan?id=' + id);
    }

    storePago(item) {
        return axios.post('api/Pago/store', item);
    }

    destroyPago(item) {
        return axios.post('api/Pago/destroy', item);
    }

    storePagoParticular(item) {
        return axios.post('api/Pago/agregarPagoAdicional', item);
    }

    

    //**************Asistencia************/
    showAsistencia(id) {
        return axios.get('api/Asistencia/show?id=' + id);
    }

    indexAsistencia() {
        return axios.defaults.baseURL + 'api/Asistencia/index';
    }

    listAsistencia() {
        return axios.get('api/Asistencia/list');
    }

    listAsistenciaEstudiante(id) {
        return axios.get('api/Asistencia/listAsistenciaEstudiante?id=' + id);
    }

    storeAsistencia(item) {
        return axios.post('api/Asistencia/store', item);
    }

    storeqrAsistencia(item) {
        return axios.post('api/Asistencia/storeqr', item);
    }

    destroyAsistencia(item) {
        return axios.post('api/Asistencia/destroy', item);
    }


    //**************TipoIngreso************/
    showTipoIngreso(id) {
        return axios.get('api/TipoIngreso/show?id=' + id);
    }

    indexTipoIngreso() {
        return axios.defaults.baseURL + 'api/TipoIngreso/index';
    }

    listTipoIngreso() {
        return axios.get('api/TipoIngreso/list');
    }

    storeTipoIngreso(item) {
        return axios.post('api/TipoIngreso/store', item);
    }

    destroyTipoIngreso(item) {
        return axios.post('api/TipoIngreso/destroy', item);
    }

    //**************DetallePago************/
    storeDetallePago(item) {
        return axios.post('api/DetallePago/store', item);
    }

    storeDetallePagoindividual(item) {
        return axios.post('api/DetallePago/storeindividual', item);
    }

    deleteDetallePago(item) {
        return axios.post('api/DetallePago/destroy', item);
    }

    //********************REPORTES */
    reporteAsistenciaEstudiante(item) {
        return axios.post('api/Asistencia/reporteAsistenciaEstudiante', item);
    }

    reportePlanPago(item) {
        return axios.post('api/Pago/reportePlanPago', item);
    }


    //**************DetallePago************/
    showRecibos(item) {
        return axios.post('api/RecibosPago/listRecibosEstudiante', item);
    }

    reporteReciboEstudiante(item) {
        return axios.post('api/RecibosPago/reporteEstudianteRecibo', item);
    }

    reporteReciboEstudianteqr(item) {
        return axios.post('api/RecibosPago/reporteEstudianteReciboqr', item);
    }

    cambiarEstadoRecibo(id) {
        return axios.get('api/RecibosPago/cambiarEstadoRecibo?id=' + id);
    }

    listRecibosEst(id) {
        return axios.get('api/RecibosPago/listRecibosEst?id=' + id);
    }

    destroyRecibo(item) {
        return axios.post('api/RecibosPago/destroy', item);
    }

    //**************CREDENCIALES************/
    indexCredenciales() {
        return axios.defaults.baseURL + 'api/Credenciales/index';
    }

    showCredenciales(id) {
        return axios.get('api/Credenciales/show?id=' + id);
    }

    storeCredenciales(item) {
        return axios.post('api/Credenciales/store', item);
    }

    reporteCredencial(item) {
        return axios.post('api/Credenciales/reporteCredencial', item);
    }

    reporteCredencialdos(item) {
        return axios.post('api/Credenciales/reporteCredencialdos', item);
    }

    //-------------------------DASHBOARD-----------------------------------

    cantidades() {
        return axios.get('api/Dashboard/cantidades');
    }

    getAsistenciaDiaria() {
        return axios.defaults.baseURL + 'api/Dashboard/getAsistenciaDiaria';
    }
    
    //-------------------------REPORTES-----------------------------------
    printestudiante(item) {
        return axios.post('api/Reporte/printestudiante', item);
    }

    printestudiantelista(item) {
        return axios.post('api/Reporte/printestudiantelista', item);
    }

    printestudiantepago(item) {
        return axios.post('api/Reporte/printestudiantepago', item);
    }

    printestudiantepagodeta(item) {
        return axios.post('api/Reporte/printestudiantepagodeta', item);
    }

    printcursopago(item) {
        return axios.post('api/Reporte/printcursopago', item);
    }

    printcursodetallado(item) {
        return axios.post('api/Reporte/printcursodetallado', item);
    }

    printcursodetalladoseis(item) {
        return axios.post('api/Reporte/printcursodetalladoseis', item);
    }

    printcursolista(item) {
        return axios.post('api/Reporte/printcursolista', item);
    }

    reportecursoasistencia(item) {
        return axios.post('api/Reporte/printcursoasistencia', item);
    }

    reportecursoasistenciageneral(item) {
        return axios.post('api/Reporte/printcursoasistenciageneral', item);
    }

    reportecursoasistenciafechas(item) {
        return axios.post('api/Reporte/printcursoasistenciafechas', item);
    }

    reporteasistenciacontrol(item) {
        return axios.post('api/Reporte/printasistenciacontrol', item);
    }

}