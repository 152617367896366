<template>
    <v-list nav dense>
        <v-list-item-group>
            <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="/"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-chart-bar</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Tablero de Control.</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-wrench</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Administración</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                    <router-link
                        class="black--text text--accent-4"
                        to="Persona"
                        active-class="blue--text text--accent-4"
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-account-multiple</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    Personal Administrativo
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                <router-link
                    class="black--text text--accent-4"
                    to="Rol"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-key</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Rol")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                </v-list-item-group>
                
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                    <router-link
                    class="black--text text--accent-4"
                    to="Sucursal"
                    active-class="blue--text text--accent-4"
                >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-school</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t("nav.Sucursal")
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                <router-link
                    class="black--text text--accent-4"
                    to="TipoIngreso"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-note-text</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Tipo Ingreso</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                </v-list-item-group>
                
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                <router-link
                    class="black--text text--accent-4"
                    to="TipoReporte"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-note-text</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.TipoReporte")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                </v-list-item-group>
                
            </v-list-group>
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>fas fa-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Cursos</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                    <router-link
                        class="black--text text--accent-4"
                        to="Curso"
                        active-class="blue--text text--accent-4"
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>fas fa-wallet</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Cursos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link
                        class="black--text text--accent-4"
                        to="CursoPara"
                        active-class="blue--text text--accent-4"
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>fas fa-folder</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Por Paralelo</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </v-list-group>

            <router-link
                class="black--text text--accent-4"
                to="Estudiante"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>far fa-user</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Estudiantes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           
            <router-link
                class="black--text text--accent-4"
                to="Credenciales"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-card-details</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Credenciales</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Pago"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>far fa-money-bill-alt</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Pagos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Asistencia"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>far fa-id-badge</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Asistencia</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="RegistrarAsistencia"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-qrcode</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Registrar Asistencia</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="RegistrarAsistenciacam"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-qrcode</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Registrar Asistencia Cam</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-file-chart</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Reportes</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item-group class="my-1" style="background-color: #e3f2fd;">
                    <router-link
                        class="black--text text--accent-4"
                        to="Reporte"
                        active-class="blue--text text--accent-4"
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-chart-line</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Reportes</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link
                        class="black--text text--accent-4"
                        to="TipoReporteVer"
                        active-class="blue--text text--accent-4"
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-engine</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t("nav.TipoReporteVer")
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </v-list-group>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
        };
    },
};
</script>