import MainService from "@/services/MainService.js";
import Loader from "@/components/Loader";
import jsQR from 'jsqr';
window.$ = window.jQuery = require("jquery");

export default {
    name: "AsistenciaPage",
    components: {
        Loader
    },
    data() {
        let ss = new MainService();
        return {
            msg: "AsistenciaPage",
            ss: ss,
            isLoading: false,
            errorBag: {},
            estudiante: {},
            qrCode: "",
            estudianteactual: {},
            image: null
        };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
                this.decodeQr();
            };
            reader.readAsDataURL(file);
        },
        decodeQr() {
            console.log("Intentando decodificar imagen");
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const img = new Image();
            img.src = this.image;
            img.onload = () => {
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height);
                if (code) {
                    this.qrCode = code.data;
                    console.log("Código QR decodificado:", this.qrCode); // Mostrar la decodificación en la consola
                    this.registrarasistenciaautomatica();
                } else {
                    console.log("No se pudo leer el código QR. Inténtelo de nuevo.");
                }
            };
        },
        registrarasistenciaautomatica() {
            if (this.qrCode) {
                this.isLoading = true;
                console.log('Código QR escaneado:', this.qrCode);
                this.ss.showqrEstudiante(this.qrCode).then(
                    (result) => {
                        let response = result.data;
                        if (response.success) {
                            this.estudiante = response.data;
                            this.estudianteactual.Estudiante = this.estudiante.id;
                            console.log('Estudiante encontrado:', this.estudiante);
                            this.ss.storeqrAsistencia(this.estudianteactual).then(
                                (result) => {
                                    let response = result.data;
                                    this.estudianteactual = response.data;
                                    this.isLoading = false;
                                    this.qrCode = "";
                                }
                            ).catch(error => {
                                console.log('Error al guardar la asistencia:', error);
                                this.isLoading = false;
                            });
                        } else {
                            console.log('Error en la respuesta del servidor:', response.msg);
                            this.isLoading = false;
                        }
                    }
                ).catch(error => {
                    console.log('Error al buscar al estudiante:', error);
                    this.isLoading = false;
                });
            }
        }
    },
    mounted() {
        // Simular el almacenamiento local para pruebas
        localStorage.setItem('persona', JSON.stringify({
            id: 1,
            Sucursal: 1,
            Rol: 1,
            ApellidoPaterno: "Del",
            ApellidoMaterno: "Sistema",
            Nombres: "Administrador",
            Persona: "Administrador del Sistema",
            Foto: null,
            email: "admin@change.me",
            Activo: true,
            TokenLogin: null,
            UltimoInicioSesion: null,
            SocialLogin: null,
            SocialLoginId: null,
            Avatar: null,
            Office365Id: null,
            created_at: null,
            updated_at: null,
            deleted_at: null,
            CreatorUserName: null,
            CreatorFullUserName: null,
            CreatorIP: null,
            UpdaterUserName: null,
            UpdaterFullUserName: null,
            UpdaterIP: null,
            DeleterUserName: null,
            DeleterFullUserName: null,
            DeleterIP: null,
            URLFoto: "http://localhost:8000/images/default_image_profile.png"
        }));

        var persona = JSON.parse(localStorage.getItem('persona'));
        console.log("Persona en localStorage:", persona);
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
